export const numberWithCommas = (x: string | number) => {
  if (x.toString().indexOf(",") !== -1 || x.toString().indexOf(".") !== -1) {
    return x;
  }
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

// sometimes we get values like 2.746665199475256e+131
export const parsePotentiallyHugeValue = (val: any) => {
  if (val > Number.MAX_SAFE_INTEGER) {
    const indexOfDot = val.toString().indexOf(".");
    if (indexOfDot >= 0) {
      const newVal = val.toString().substring(0, indexOfDot + 2);
      if (parseFloat(newVal) !== NaN) {
        return parseFloat(newVal);
      }
      return 0;
    }
    return 0;
  }
  return val;
};

export const toFixed = (value: any, precision: number) => {
  if (value === undefined || value === null) {
    return "";
  } else if (+value < 0 || isNaN(+value)) {
    return "N/A";
  } else {
    return Number(value).toFixed(precision);
  }
};

const isValidMetric = (value: any) => {
  return !isNaN(+value) && value !== null && value !== undefined && +value >= 0;
}

export const parseMetric = (combined: any, outgoing: any, incoming: any) => {
  if (isValidMetric(combined)) {
    return combined;
  } else if (isValidMetric(incoming) && isValidMetric(outgoing)) {
    return (incoming + outgoing) / 2;
  } else if (isValidMetric(outgoing)) {
    return outgoing;
  } else if (isValidMetric(incoming)) {
    return incoming;
  }
  return null;
}
