import { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Theme } from "@mui/material";

import { ExportFormat } from "../types";
import { createStyles, makeStyles } from "@mui/styles";

interface ExportDialogProps {
  format: ExportFormat | undefined;
  retentionPeriodDays: number | null;
  rowLimit: number | null;
  columnNames: string[];
  doExport: (format: ExportFormat) => Promise<boolean>;
  closeDialog: () => void;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    dialogPaper: { maxWidth: 600, width: "100%" },
    dialogTitle: { position: "relative" },
    rowNoteHeading: {},
    rowDelimiterHeading: {},
    rowLimitHeading: {},
    columnListHeading: {},
    columnListWrapper: {
      margin: "8px 0 0",
      display: "flex",
      columnGap: 42,
      flexWrap: "wrap",
    },
    columnList: {
      margin: "16px 0 0",
      padding: "0 0 0 18px",
      display: "flex",
      flexDirection: "column",
      rowGap: 16,
      columnGap: 24,
      maxHeight: "60vh",
      flexWrap: "wrap",
    },
    columnListItem: {
      flex: 0,
      fontSize: 14,
      lineHeight: "16px",
    },
  })
);

const ExportDialog = ({
  format,
  retentionPeriodDays,
  rowLimit,
  columnNames,
  doExport,
  closeDialog,
}: ExportDialogProps) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [savedFormat, setSavedFormat] = useState(format);

  useEffect(() => {
    if (format) {
      setSavedFormat(format);
    }
  }, [format]);

  const handleExport = async () => {
    if (format) {
      setIsLoading(true);

      const result = await doExport(format);

      if (result) {
        closeDialog();
      }

      setIsLoading(false);
    }
  };

  const getLimitCaveat = (rowLimit: number | null, retentionPeriodDays: number | null): string | null => {
    if (rowLimit === null && retentionPeriodDays !== null) {
      return `Records from the last ${retentionPeriodDays} days will be downloaded`;
    } else if (rowLimit !== null && retentionPeriodDays === null) {
      return `A maximum of ${rowLimit} records will be downloaded`;
    } else if (rowLimit !== null && retentionPeriodDays !== null) {
      return `A maximum of ${rowLimit} records from the last ${retentionPeriodDays} days will be downloaded`;
    } else {
      return null;
    }
  };

  const displayFormat = (format ?? savedFormat)?.toUpperCase();
  const limitCaveat = getLimitCaveat(rowLimit, retentionPeriodDays);

  return (
    <Dialog
      open={Boolean(format)}
      onClose={closeDialog}
      aria-labelledby="export-dialog"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={classes.dialogTitle}>Download {displayFormat}</DialogTitle>

      <DialogContent>
        <Typography variant="body1" className={classes.rowNoteHeading}>
          Before you proceed with the {displayFormat} download, please note the following:
        </Typography>
        {savedFormat === "csv" && (
          <>
            <Typography variant="body1" className={classes.rowDelimiterHeading}>
              - We use commas as delimiters in our CSV files.
            </Typography>
          </>
        )}
        {limitCaveat !== null && (
          <>
            <Typography variant="body1" className={classes.rowLimitHeading}>
              - {limitCaveat}
            </Typography>
          </>
        )}
        <Typography variant="body1" className={classes.columnListHeading}>
          - The {displayFormat} will include the following columns:
        </Typography>

        <div className={classes.columnListWrapper}>
          <ul className={classes.columnList}>
            {columnNames.map((columnName) => (
              <li className={classes.columnListItem} key={columnName}>
                {columnName}
              </li>
            ))}
          </ul>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} color="textSecondary">
          Cancel
        </Button>
        <Button onClick={handleExport} disabled={isLoading} variant="contained">
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
