export default {
  auth: {
    loginLocal: "/auth/local",
    loginSso: "/auth/sso-url/",
  },
  api: {
    user: {
      me: "/api/users/me",
      changeProject: "/api/users/me/change-project",
      resetPassword: "/api/users/reset-password",
      confirmAccount: "/api/users/confirm-account",
      addTestToFavorites: "/api/test_definitions/like",
      logout: "/api/users/logout",
    },
    dashboards: "/api/dashboards",
    testDefinitions: "/api/test_definitions",
    testIterations: "/api/test_iterations",
    testIterationsHistory: "/api/test_iterations/history",
    testIterationsHistoryExport: "/api/test_iterations/history/:id/export/:format",
    testIterationsUnassigned: "/api/test_iterations/unassigned",
    testIterationsScreenshot: "/api/test_iterations/get-screen-shot",
    testIterationsImport: "/api/test_definitions/import",
    testIterationsInfo: "/api/test_iterations/info",
    testIterationsTrace: "/api/test_iterations/trace",
    testRuns: "/api/test_runs",
    testRunsExport: "/api/test_runs/export",
    testRunsHistoryExport: "/api/test_runs/history/export/:format",
    analyzeDatas: "api/analyzeDatas/report",
    analyzeDatasFree: "api/analyzeDatas/free",
    analyzeDataHistory: "api/analyzeDatas/history",
    monitorsCopy: "/api/monitors/copy",
    monitors: "/api/monitors",
    monitorRuns: "/api/test_runs/monitor",
    monitorRunsExport: "/api/test_runs/monitor/export",
    monitorRunsHistoryExport: "/api/test_runs/monitors/history/export/:format",
    testRunsDistinct: "/api/test_runs/test/distinct",
    monitorRunsDistinct: "/api/monitors/distinct",
    qualityRTC: "/api/network-test",
    qualityRTCExport: "/api/network-test/history/export/:format",
    networkTestAg: "/api/network-test/ag",
    embedNetworkTestAg: "/api/network-test/embedAg",
    assets: "/api/additional-assets",
    assetDuplicate: "/api/additional-assets/duplicate",
    assetImport: "/api/additional-assets/import",
    run: "/api/test_definitions/run",
    copy: "/api/test_definitions/copy",
    userConfig: "/api/config-datas/user-config",
    emailUnique: "/api/users/unique",
    requestPassword: "/api/users/request-password",
    newsletterSubscribe: "/api/users/newsletter-subscribe",
    signup: "api/users",
    users: "api/users",
    settings: {
      root: "api/users/settings",
      emails: "api/users/settings/test-uprtc-emails",
    },
    accountUsers: "api/users/account-users",
    accountRoles: "api/users/account-roles",
    projects: {
      root: "api/projects",
      all: "api/projects/all",
      search: "api/projects/search",
      removeUser: "api/projects/remove-user",
      dataStreams: "api/projects/data-streams",
    },
    probes: {
      all: "api/agents/all",
      delete: "api/agents/remove",
    },
    apiKey: "/api/api-key",
    onPremiseProbes: "api/on-premise-probe",
    onPremiseProbesHistory: "api/on-premise-probe/history",
    onPremiseProbesDuplicate: "/api/on-premise-probe/duplicate",
    onPremiseProbesImport: "/api/on-premise-probe/import",
    onPremiseProbesGenerateApiKey: "/api/on-premise-probe/generate-apikey",
    onPremiseProbeSetup: "/api/on-premise-probe/probeSetup",
    onPremiseProbesExport: "/api/on-premise-probe/export/:format",
    probeRTC: "api/probeRTC",
    probeRTCHistory: "api/probeRTC/history",
    probeRTCHistoryExport: "/api/probeRTC/history/:id/export/:format",
    probeRTCCheckVideoTests: "/api/probeRTC/hasVideoTests/:id",
    // admin endpoints
    userAccounts: "api/be_users",
    userDetails: "api/users/group",
    companies: "api/be_users/companies",
    resetPassword: (userId: string) => `api/be_users/reset-password/${userId}`,
    removeSuspension: (userId: string) => `api/be_users/remove-suspension/${userId}`,
    influexQuery: "/api/adminops/influxdb/query",
    statusPage: "/api/status-page",
    adminUtils: {
      globalConfig: "/api/config-datas/main/config",
      globalProbes: "/api/config-datas/config-probe",
      globalBrowser: "/api/config-datas/config-browser",
      agentAssure: "/api/config-datas/agent-assure",
      insightsConfig: "/api/config-datas/insights-config",
      exportConfig: "/api/config-datas/export-feature",
      updateGlobalConfig: "/api/config-datas/main",
      testMonitorJobQueueInfo: "/api/adminops/bull-info",
      getAgentAllocation: "/api/adminops/get-agent-allocation",
      getTestEndpoint: "/api/adminops/get-test-endpoint",
      fillCalculatedFields: "/api/adminops/migration",
      exportCSV: "/api/adminops/project-all/csv",
      freeReportFeedback: "/api/adminops/free-report-feedback",
      testUpRtcEmails: "/api/adminops/test-uprtc-emails",
      gscDownload: "/api/adminops/gsc-download",
      liveStatsDownload: "/api/adminops/live-stats-download",
      adminCmd: "/api/adminops/admin-cmd",
      customIPCsvUpload: "api/adminops/custom-ip-csv-upload",
    },
    billingData: "/api/test_runs/adminIndex",
    billingDataExport: "/api/test_runs/export/billingData",
    gridPreset: {
      root: "/api/gridPreset",
    },
    watchRTCRoom: "/api/watchRTCRoom",
    watchRTCRoomHistoryExport: "/api/watchRTCRoom/history/export/:format",
    watchRTCPeer: "/api/watchRTCPeer",
    watchRTCPeerHistoryExport: "/api/watchRTCPeer/peers/export/:format",
    watchRTCPeerInfo: "/api/watchRTCPeer/info",
    watchRTCPeerTrace: "/api/watchRTCPeer/trace",
    watchRTCPeerHistoryIdExport: "/api/watchRTCPeer/history/:id/export/:format",
    watchRTCHighlights: "/api/watchRTCHighlights",
    watchRTCTrends: "/api/watchRTCTrends",
    watchRTCTrendsRulesCodes: "/api/watchRTCTrends/rules-codes",
    watchRTCLive: "/api/watchRTCLive",
    watchRTCLiveTrace: "/api/watchRTCLive/trace",
    watchRTCExpectation: "/api/watchRTCExpectation",
    watchRTCNotification: "/api/watchRTCNotification",
    watchRTCFilterView: "/api/watchRTCFilterView",
    watchRTCUsageGraph: "/api/watchRTCUsageGraph",
    analyticsEvents: "/api/analytics-events",
    analyzeRTC: "/api/analyze-rtc",
    qualityRTCInvite: "/api/qualityRTCInvite",
    qualityRTCInviteDuplicate: "/api/qualityRTCInvite/duplicate",
    qualityRTCInviteHistoryExport: "/api/qualityRTCInvite/history/export/:format",
    qualityRTCInviteHistoryIdExport: "/api/qualityRTCInvite/history/:id/export/:format",
    qualityRtcInviteFields: "/api/projects/qualityRtcInviteFields",
    performanceDashboardTestNames: "/api/test_definitions/test-names",
    performanceDashboardChartData: "/api/test_runs/charts-data",
    netTestBenchmark: "/api/net-test-benchmark",
  },
  frame: {
    showProjectConfig: "/iframe/showProjectConfig",

    showRoom: "/iframe/showRoom/:id?",
    showRoomStats: "/iframe/showRoomStats/:id?",
    showRoomPeersIds: "/iframe/showRoomPeersIds/:id?",
    showRoomPeersList: "/iframe/showRoomPeersList/:id?",
    showRoomPeerEvents: "/iframe/showPeerEvents/:id?",

    showPeer: "/iframe/showPeer/:id?",
    showPeerChartData: "/iframe/showPeerChartData/:id?",
    showPeerUserMedia: "/iframe/showPeerUserMedia/:id?",
    showPeerTrace: "/iframe/showPeerTrace/:id?",
    showPeerInfo: "/iframe/showPeerInfo/:id?",

    getAdvanceWebRtc: "/iframe/getAdvanceWebRtc/:fileUrl/:machineName/:action",
  },
};
