const WATCH_RTC_ROOMS_HISTORY_TABLE_COLUMNS = [
  "ID",
  "Comment",
  "Time",
  "Duration",
  "Room",
  "Peers",

  // Alerts column
  "Warnings",
  "Errors",

  "Score",
  "Bucket",
];

const WATCH_RTC_ROOM_PEERS_TABLE_COLUMNS = [
  "Room Name",
  "Peer Name",
  "Probe URL",
  "#",

  "Browser",
  "Browser Version",
  "OS",

  "Location",
  "Remote Location",

  "Notifications",

  "Test Start Time",
  "Session Start Time",
  "Session Duration", // in milliseconds
  "Call Setup Time", // in milliseconds

  "Score",
  "MOS",

  // in kbit/s
  "Bitrate Audio In",
  "Bitrate Audio Out",
  "Bitrate Video In",
  "Bitrate Video Out",

  // in percentage
  "Packet Loss Audio In",
  "Packet Loss Audio Out",
  "Packet Loss Video In",
  "Packet Loss Video Out",

  // in milliseconds
  "Jitter Audio In",
  "Jitter Audio Out",
  "Jitter Video In",
  "Jitter Video Out",

  // in milliseconds
  "RTT Audio Out",
  "RTT Video Out",
];

const WATCH_RTC_PEERS_HISTORY_TABLE_COLUMNS = [
  "ID",
  "Comment",
  "Time",
  "Duration",
  "Peer",
  "Room",

  // Alerts column
  "Warnings",
  "Errors",

  "Score",
  "Browser",
  "OS",
  "Location"
];

const QUALITY_RTC_TESTS_HISTORY_TABLE_COLUMNS = [
  "Status",
  "Date",
  "Score",
  "Email",
  "User ID",// optional
  "Organization",// optional
  "Reason",
  "Location",
  "Insights", // insights
  "Errors",
  "Call Quality MOS",
  "Bandwidth Speed Uplink",
  "Bandwidth Speed Downlink",
  "Bandwidth Speed Jitter",
  "Call Quality Packet Loss",
  "Call Quality Jitter",
  "Call Quality RTT",
  "ID",
  "Day",
  "Region",
  "Test Time",
  "IP",
  "Throughput Min",
  "Throughput Avg",
  "TURN Connectivity UDP",
  "TURN Connectivity TCP",
  "Video Quality Bitrate In",
  "Video Quality Bitrate Out",
  "Video Quality Loss In",
  "Video Quality Loss Out",
  "PING",
  "URL",
  "Account",
  "Score Status",
  "First Name", // optional
  "Last Name", // optional
];

const QUALITY_RTC_INVITES_HISTORY_TABLE_COLUMNS = [
  "Invite Name",
  "URL",
  "Owner",
  "Runs",
  "Runs Left",
  "Expiration Date",
];

const PROBE_RTC_PROBES_LIST_TABLE_COLUMNS = ["ID", "Active", "Name", "Last Active", "Location"];

const PROBE_RTC_PROBE_HISTORY_TABLE_COLUMNS = [
  "Status",
  "Date Time",
  "Run Type",
  "Location",
  "Errors",
  "Call Quality MOS In",
  "Call Quality MOS Out",
  "Bandwidth Speed Uplink",
  "Bandwidth Speed Downlink",
  "Bandwidth Speed Jitter",
  "Call Quality Packet Loss In",
  "Call Quality Packet Loss Out",
  "Call Quality Jitter In",
  "Call Quality Jitter Out",
  "Call Quality RTT",
  "ID",
  "Test Time",
  "Throughput Min",
  "Throughput Avg",
  "TURN Connectivity UDP",
  "TURN Connectivity TCP",
  "Video Quality Bitrate In",
  "Video Quality Bitrate Out",
  "Video Quality Packet Loss In",
  "Video Quality Packet Loss Out",
  "PING",
  "Video Bandwidth Estimate",
  "Video Bandwidth Packet Loss",
  "Video Bandwidth Jitter",
  "Video Bandwidth RTT",
  "DNS Lookup Total Connections",
  "DNS Lookup Successful Connections",
  "DNS Lookup Average Connection Time",
  "DNS Lookup Highest Connections Time",
  "DNS Lookup Shortest Connections Time",
  "Video P2P Bandwidth Estimate Out",
  "Video P2P Bitrate",
  "Video P2P Bitrate Out",
  "Video P2P Packet Loss",
  "Video P2P Packet Loss Out",
  "Video P2P RTT"
];

const TESTING_RTC_TEST_PROBES_TABLE_COLUMNS = [
  "Probe",
  "Probe URL",
  "Machine",
  "Status",
  "Notifications",

  "IP",
  "OS",
  "Browser",
  "Location",
  "Network",
  "Firewall",
  "Media",
  "Session #",
  "Session Size",
  "Session Index",

  "Test Start Time",
  "Test Duration",
  "Connection Start Time",
  "Connection Duration",
  "Call Setup Time",

  "Score",
  "MOS",
  "CPU",
  "Memory",

  // in kbit/s
  "Bitrate Audio In",
  "Bitrate Audio In (min)",
  "Bitrate Audio In (max)",

  "Bitrate Audio Out",
  "Bitrate Audio Out (min)",
  "Bitrate Audio Out (max)",

  "Bitrate Video In",
  "Bitrate Video In (min)",
  "Bitrate Video In (max)",

  "Bitrate Video Out",
  "Bitrate Video Out (min)",
  "Bitrate Video Out (max)",

  "Packet Loss Audio In",
  "Packets Lost Audio In",
  "Packets Audio In",

  "Packet Loss Audio Out",
  "Packets Lost Audio Out",
  "Packets Audio Out",

  "Packet Loss Video In",
  "Packets Lost Video In",
  "Packets Video In",

  "Packet Loss Video Out",
  "Packets Lost Video Out",
  "Packets Video Out",

  // in milliseconds
  "Jitter Audio In",
  "Jitter Audio In (min)",
  "Jitter Audio In (max)",

  "Jitter Audio Out",
  "Jitter Audio Out (min)",
  "Jitter Audio Out (max)",

  "Jitter Video In",
  "Jitter Video In (min)",
  "Jitter Video In (max)",

  "Jitter Video Out",
  "Jitter Video Out (min)",
  "Jitter Video Out (max)",

  // in milliseconds
  "RTT Audio Out",
  "RTT Audio Out (min)",
  "RTT Audio Out (max)",

  "RTT Video Out",
  "RTT Video Out (min)",
  "RTT Video Out (max)",
];

const TESTING_RTC_TESTS_HISTORY_TABLE_COLUMNS = [
  "Name",
  "Machines",
  "Time",
  "URL",
  "Status",
  "Failure Reason",

  "Session Size",
  "Number of Probes Success",
  "Number of Probes Warning",
  "Number of Probes Failure",

  "Test Duration",
  "Connection Duration",
  "Call Setup Time",

  "Score",
  "MOS",
  "CPU",
  "Memory",

  // in kbit/s
  "Bitrate Audio In",
  "Bitrate Audio Out",
  "Bitrate Video In",
  "Bitrate Video Out",

  // in percentage
  "Packet Loss Audio In",
  "Packet Loss Audio Out",
  "Packet Loss Video In",
  "Packet Loss Video Out",

  // in milliseconds
  "Jitter Audio In",
  "Jitter Audio Out",
  "Jitter Video In",
  "Jitter Video Out",

  // in milliseconds
  "RTT Audio Out",
  "RTT Video Out",
];

const UP_RTC_TESTS_HISTORY_TABLE_COLUMNS = [
  "Name",
  "Monitor Name",
  "Machines",
  "Time",
  "URL",
  "Status",
  "Failure Reason",

  "Session Size",
  "Number of Probes Success",
  "Number of Probes Warning",
  "Number of Probes Failure",

  "Test Duration",
  "Connection Duration",
  "Call Setup Time",

  "Score",
  "MOS",
  "CPU",
  "Memory",

  // in kbit/s
  "Bitrate Audio In",
  "Bitrate Audio Out",
  "Bitrate Video In",
  "Bitrate Video Out",

  // in percentage
  "Packet Loss Audio In",
  "Packet Loss Audio Out",
  "Packet Loss Video In",
  "Packet Loss Video Out",

  // in milliseconds
  "Jitter Audio In",
  "Jitter Audio Out",
  "Jitter Video In",
  "Jitter Video Out",

  // in milliseconds
  "RTT Audio Out",
  "RTT Video Out",
];

export {
  WATCH_RTC_ROOMS_HISTORY_TABLE_COLUMNS,
  WATCH_RTC_PEERS_HISTORY_TABLE_COLUMNS,
  WATCH_RTC_ROOM_PEERS_TABLE_COLUMNS,
  QUALITY_RTC_TESTS_HISTORY_TABLE_COLUMNS,
  QUALITY_RTC_INVITES_HISTORY_TABLE_COLUMNS,
  PROBE_RTC_PROBES_LIST_TABLE_COLUMNS,
  PROBE_RTC_PROBE_HISTORY_TABLE_COLUMNS,
  TESTING_RTC_TEST_PROBES_TABLE_COLUMNS,
  TESTING_RTC_TESTS_HISTORY_TABLE_COLUMNS,
  UP_RTC_TESTS_HISTORY_TABLE_COLUMNS,
};
